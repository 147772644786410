<template>
    <div class="home">
        <a-breadcrumb>
            <a-breadcrumb-item href="">
                <a-icon type="home" />
            </a-breadcrumb-item>
            <a-breadcrumb-item href="">
                <a-icon type="schedule" />
                <span>管理</span>
            </a-breadcrumb-item>
            <a-breadcrumb-item> 投诉与建议 </a-breadcrumb-item>
        </a-breadcrumb>

        <div class="search-list-box">
            <a-form class="ant-advanced-search-form" :form="form" @submit="handleSearch">
                <a-row :gutter="24">
                    <a-col :span="4">
                        <a-form-item :label="`用户名称：`">
                            <a-input placeholder="请输入用户名称" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="4">
                        <a-form-item :label="`手机号码：`">
                            <a-input placeholder="请输入手机号码" />
                        </a-form-item>
                    </a-col>

                    <a-col :span="4">
                        <a-button type="primary" @click="getList"> 查询 </a-button>
                        <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                            重置
                        </a-button>
                    </a-col>
                </a-row>
            </a-form>
            <div class="search-result-list">
                <div class="table-header-box">
                    <div class="left">
                        <h3 class="title">投诉与建议列表</h3>
                    </div>
                    <div class="right">
                        <a-button type="primary" class="marginr20" html-type="submit" @click="show1 = true">
                            详情
                        </a-button>
                    </div>
                </div>
                <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                    :columns="columns" @change="handleTableChange" :pagination="{ total: total_page * 10 }"
                    :data-source="list" rowKey="art_id" size="small">
                </a-table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
const columns = [
    { title: "类型", dataIndex: "type", },
    { title: "联系人", dataIndex: "real_name", },
    { title: "联系电话", dataIndex: "mobile", },
    { title: "发布账号", dataIndex: "user_name", },
    { title: "账号类型", dataIndex: "user_type", },
    { title: "发布时间", dataIndex: "address", },
];

export default {
    data() {
        return {
            expand: false,
            form: this.$form.createForm(this, { name: "advanced_search" }),
            columns,
            searchData: {
                page: 1,
                keywords: '',
                start_date: '',
                end_date: ''
            },
            list: [],
            total_page: 0,
            selectedRowKeys: [],
            selectedRowDataList: [],
            pickerKey: Math.random()
        };
    },
    methods: {
        moment,
        handleSearch(e) {
            e.preventDefault();
            this.form.validateFields((error, values) => {
            });
        },

        handleReset() {
            this.form.resetFields();
        },
        // 日期解析
        handleChangeDate(date) {
            this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
            this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
        },
        // 表格分页
        handleTableChange(pagination, filters, sorter) {
            this.searchData.page = pagination.current
            this.getList();
        },
        // 表格选择
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        getList() {

        }
    },
};
</script>
<style>
.ant-advanced-search-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
    display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}

.search-list-box .ant-form {
    max-width: none;
}

.search-list-box .search-result-list {
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    border-radius: 6px;
    background-color: #fafafa;
    min-height: 200px;
    text-align: center;
    padding-top: 80px;
}
</style>
